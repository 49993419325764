'use strict'

const _ = require('lodash')
const behaviorsDataFixer = require('../plugins/behaviorsDataFixer')

function getBehaviorsItemPath(comp) {
    return ['behaviors_data', comp.behaviorQuery, 'items']
}

function wereBehaviorsMigrated(pageData) {
    return _.has(pageData, 'behaviors_data')
}

function setFixedBehaviors(behaviorArr, comp, pageData) {
    const behaviorsToSet = JSON.stringify(behaviorArr)
    if (wereBehaviorsMigrated(pageData)) {
        _.set(pageData, getBehaviorsItemPath(comp), behaviorsToSet)
    } else {
        comp.behaviors = behaviorsToSet
    }
}

function getCompBehaviors(comp, pageData) {
    if (wereBehaviorsMigrated(pageData)) {
        return _.get(pageData, getBehaviorsItemPath(comp), '[]')
    }
    return comp.behaviors || '[]'
}

/**
 * Convert old behaviors structure to new behaviors structure
 * @param comp
 */
function flattenBehaviors(comp, pageData) {
    const behaviorsList = []
    const behaviorsString = getCompBehaviors(comp, pageData)
    const behaviorsObj = JSON.parse(behaviorsString)
    const isBehaviorsArray = _.isArray(behaviorsObj)
    const behaviorsWithoutNull = isBehaviorsArray ? _.compact(behaviorsObj) : behaviorsObj

    if (_.isEmpty(behaviorsWithoutNull)) {
        if (wereBehaviorsMigrated(pageData)) {
            _.unset(pageData, ['behaviors_data', comp.behaviorQuery])
            delete comp.behaviorQuery
        } else {
            delete comp.behaviors
        }
        return
    }

    if (isBehaviorsArray) {
        let shouldFix = behaviorsWithoutNull.length !== behaviorsObj.length
        _.forEach(behaviorsObj, behavior => {
            const sourceId = _.get(behavior, 'action.sourceId')
            if (sourceId && sourceId !== comp.id) {
                behavior.action.sourceId = comp.id
                shouldFix = true
            }
        })

        if (shouldFix) {
            setFixedBehaviors(behaviorsWithoutNull, comp, pageData)
        }
        return
    }

    _.forEach(behaviorsObj, (targets, actionName) => {
        _.forEach(targets, (behaviors, targetId) => {
            _.forEach(behaviors, behavior => {
                const extendedBehavior = _.assign({targetId, action: actionName}, behavior)
                if (actionName === 'screenIn' || actionName === 'pageIn') {
                    extendedBehavior.targetId = ''
                }
                behaviorsList.push(extendedBehavior)
            })
        })
    })

    setFixedBehaviors(behaviorsList, comp, pageData)
}

function fixComponentBehaviors(comps, pageData) {
    _.forEach(comps, comp => {
        flattenBehaviors(comp, pageData)
        if (comp.components) {
            fixComponentBehaviors(comp.components, pageData)
        }
    })
}

/**
 * @exports utils/dataFixer/plugins/behaviorsFixer
 * @type {{exec: exec}}
 */
module.exports = {
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) {
        behaviorsDataFixer.exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject)
        const structureData = pageJson.structure
        if (!structureData) {
            return
        }
        if (structureData.components) {
            fixComponentBehaviors(structureData.components, pageJson.data)
        }
        if (structureData.mobileComponents) {
            fixComponentBehaviors(structureData.mobileComponents, pageJson.data)
        }
        if (structureData.children) {
            fixComponentBehaviors(structureData.children, pageJson.data)
        }
    }
}
