'use strict'
const _ = require('lodash')
const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')

const INPUTS_WITH_PLACEHOLDERS = [
    'wysiwyg.viewer.components.inputs.TextInput',
    'wixapps.integration.components.inputs.TextArea',
    'wysiwyg.viewer.components.inputs.TextAreaInput',
    'wysiwyg.viewer.components.inputs.ComboBoxInput',
    'wysiwyg.viewer.components.inputs.DatePicker'
]

function fixInputPlaceholder(pageJson, cache, mobileView, comp) {
    const placeholder = _.get(this.getComponentProperties(pageJson, comp), 'placeholder')
    const compData = this.getComponentData(pageJson, comp)
    const newCompData = _.merge({placeholder}, compData)

    this.setComponentData(pageJson, comp, newCompData, cache)
}

const MIGRATION_MAP = _.mapValues(_.keyBy(INPUTS_WITH_PLACEHOLDERS), () => fixInputPlaceholder)

/**
 * @exports utils/dataFixer/plugins/inputsPlaceholderDataFixer
 * @type {{exec: exec}}
*/
module.exports = {exec: createHelperAndMigratePage(MIGRATION_MAP)}
