'use strict'

const _ = require('lodash')
const coreUtils = require('santa-core-utils')

const STYLED_TEXT_TYPE = 'StyledText'

/**
 * @exports utils/dataFixer/plugins/richTextSkinDataFixer
 * @type {{exec: exec}}
 *
 */

const collectUsedFonts = styledTextDataItem => {
    const usedFonts = []

    coreUtils.htmlParser(styledTextDataItem.text, {
        start(tagName, attributes/*, isSingleton, tag*/) {
            const style = _.find(attributes, {name: 'style'})
            if (!style) {
                return
            }

            const fontFamily = /font-family:([^;]+);?/ig.exec(style.value)
            if (fontFamily) {
                usedFonts.push(fontFamily[1].trim())
            }
        }
    })

    _.set(styledTextDataItem, 'usedFonts', usedFonts)
}

const collectUsedFontsFromData = json => {
    _(json.data.document_data)
        .filter(({type, usedFonts}) => !usedFonts && type === STYLED_TEXT_TYPE)
        .forEach(collectUsedFonts)
}

module.exports = {
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) {
        if (!magicObject.isExperimentOpen('sv_usedFontsDataFixer')) {
            return
        }

        try {
            collectUsedFontsFromData(pageJson)
            _(pageJson.translations).forEach(collectUsedFontsFromData)
        } catch (e) {
            // it's not my fault, there's nothing I can do :(
        }

        return pageJson
    }
}
