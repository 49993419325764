'use strict'
const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')
const coreUtils = require('santa-core-utils')
const _ = require('lodash')

const MIGRATION_MAP = {
    'wysiwyg.viewer.components.MatrixGallery': addCompItemHeight
}
const MIN_GALLERY_HEIGHT = 70
const MAP_SKIN_TO_EXPORTS = {
    'skins.viewer.gallerymatrix.MatrixGalleryIronSkin': {heightDiff: 40},
    'skins.viewer.gallerymatrix.MatrixGalleryScotchTapeSkin': {heightDiff: 50},
    'wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGallerySeparateTextBoxSkin': {heightDiff: 40},
    'wysiwyg.common.components.matrixgallery.viewer.skins.MatrixGalleryTextOnCenterSkin': {heightDiff: 25},
    'wysiwyg.viewer.skins.displayers.CircleDetailedDisplayer': {heightDiff: 0},
    'wysiwyg.viewer.skins.displayers.PolaroidDisplayer': {heightDiff: 40},
    'wysiwyg.viewer.skins.gallery.CircleMatrixGallery': {heightDiff: 40},
    'wysiwyg.viewer.skins.gallery.MatrixGallerySkin': {heightDiff: 40},
    'wysiwyg.viewer.skins.gallery.PolaroidMatrixGallery': {heightDiff: 0},
    'wysiwyg.viewer.skins.gallerymatrix.BlogMatrixGallery': {heightDiff: 25},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryCircleSkin': {heightDiff: 40},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerVintage': {heightDiff: 80},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryDefaultSkin': {heightDiff: 25},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryLiftedShadow': {heightDiff: 25},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryPolaroidSkin': {heightDiff: 25},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGallerySloopy': {heightDiff: 25},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTextBelowSkin': {heightDiff: 25},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTextSlideUpSkin': {heightDiff: 25},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryTransparentSkin': {heightDiff: 25},
    'wysiwyg.viewer.skins.gallerymatrix.MatrixGalleryVintage': {heightDiff: 25},
    'wysiwyg.viewer.skins.gallerymatrix.PolaroidCustomHeightSkin': {heightDiff: 25},
    'wysiwyg.viewer.skins.gallerymatrix.TextBottomCustomHeightSkin': {heightDiff: 25}
}

function addCompItemHeight(pageJson, cache, mobileView, comp) {
    const compProp = this.getComponentProperties(pageJson, comp)
    if (compProp && !_.isNumber(compProp.itemHeight)) {
        const compData = this.getComponentData(pageJson, comp)

        const height = Math.max(MIN_GALLERY_HEIGHT, _.get(comp, ['layout', 'height']))
        const compStyleId = comp.styleId
        const compStyle = _.get(pageJson, ['data', 'theme_data', compStyleId])
        const skin = _.get(compStyle, 'skin') || _.get(comp, 'skin')
        const heightDiff = _.get(MAP_SKIN_TO_EXPORTS, [skin, 'heightDiff']) || 0
        const numberOfRows = coreUtils.matrixCalculations.getAvailableRowsNumber(compProp.maxRows, compProp.numCols, compData.items.length)


        const itemHeight = coreUtils.matrixCalculations.getItemHeight(compProp.margin, height, numberOfRows, heightDiff)
        const newCompProp = _.merge({itemHeight}, compProp)
        this.setComponentProperties(pageJson, comp, newCompProp, cache)
    }
}

module.exports = {
    exec: createHelperAndMigratePage(MIGRATION_MAP, 'sv_add_item_height_matrix_gallery'),
    comps: Object.keys(MIGRATION_MAP)
}