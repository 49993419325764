'use strict'

const _ = require('lodash')
const coreUtils = require('santa-core-utils')
const featuresData = require('document-services-json-schemas/dist/featuresData.json')
const queryBlackList = _.map(featuresData, 'query')//non public data queries

const eliminateBlackListedQueries = comp => {
    if (!_.isObject(comp)) {
        return
    }
    _.forEach(queryBlackList, query => delete comp[query])
}

module.exports = {
    exec: //pageJson => {
        (pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) => {
            if (magicObject.isViewerMode) {
                const desktopComponents = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, false)
                const mobileComponents = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, true)

                _.forEach(desktopComponents, eliminateBlackListedQueries)
                _.forEach(mobileComponents, eliminateBlackListedQueries)
            }
        }
}