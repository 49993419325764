'use strict'

const _ = require('lodash')
const REF_ARRAY_TYPE = 'RefArray'
const VARIANT_RELATION_TYPE = 'VariantRelation'
const BREAKPOINT_RELATION_TYPE = 'BreakpointRelation'

/**
 * @param {string} query
 * @return {string}
 */
function stripHashIfExists(query) {
    return query && query.replace('#', '')
}

const variantRelation = {
    /**
     * Checks if its variant relation
     * @param {Object} styleData
     * @returns {boolean}
     */
    isVariantRelation(obj) {
        return obj.type === VARIANT_RELATION_TYPE
    },

    /**
     * Returns variants property of provided object
     * @param {Object} data
     * @returns {boolean}
     */
    extractVariantsWithoutHash: obj => obj && obj.variants ? _.map(obj.variants, stripHashIfExists) : false,
    /**
     * Returns to property of provided object without # prefix
     * @param {Object} data
     * @returns {string}
     */
    extractToWithoutHash: obj => obj && obj.to && stripHashIfExists(obj.to)
}

const breakpointRelation = {
    /**
     * Checks if its breakpoint relation
     * @param {Object} data
     * @returns {boolean}
     */
    isBreakpointRelation: data => data && data.type === BREAKPOINT_RELATION_TYPE,

    /**
     * Returns breakpoint property of provided object without # prefix
     * @param {Object} data
     * @returns {string}
     */
    extractBreakpointWithoutHash: data => data && data.breakpoint && stripHashIfExists(data.breakpoint),

    /**
     * Returns ref property of provided object without # prefix
     * @param {Object} data
     * @returns {string}
     */
    extractRefWithoutHash: data => data && data.ref && stripHashIfExists(data.ref)

}

/**
 * this is copy-paste from ds-impl dataModel
 * @see {@link https://github.com/wix-private/document-management/blob/master/document-services-implementation/src/dataModel/dataModel.js}
 * @type {{extractValuesWithoutHash, create, update, isRefArray, extractValues}}
 */
const refArray = {
    /**
     * Returns values property of provided object
     *
     * @param {Object} obj
     * @returns {Array}
     */
    extractValues: obj => _.get(obj, 'values', []),
    /**
     * Returns values key of provided object stripping hashes(#)
     * @param {Object} obj
     * @returns {string[]}
     */
    extractValuesWithoutHash: obj => _.map(refArray.extractValues(obj), stripHashIfExists),
    /**
     * Returns reference array object
     * @param {Array} values
     * @returns {{values: Array, type: string}}
     */
    create: (values = []) => ({
        type: REF_ARRAY_TYPE,
        values: _.map(values, v => `#${v.replace('#', '')}`)
    }),
    /**
     * returns new object, based on obj with provided values property
     *
     * @param {Object} obj
     * @param {Array} values
     * @returns {Object}
     */
    update: (obj, values) => ({
        ...obj,
        values: _.map(values, v => `#${v.replace('#', '')}`)
    }),
    /**
     * Check if object has reference type
     *
     * @param {Object} obj
     * @returns {boolean}
     */
    isRefArray: obj => obj && obj.type === REF_ARRAY_TYPE
}


module.exports = {
    refArray,
    variantRelation,
    breakpointRelation,
    stripHashIfExists
}
