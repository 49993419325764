'use strict'
// behaviors_data sample
/*
{
  "behavior-jg9pfpzt": {
    "type": "ObsoleteBehaviorsList",
    "id": "behavior-jg9pfpzt",
    "metaData": {
      "isPreset": false,
      "schemaVersion": "1.0",
      "isHidden": false
    },
    "items": "[]"
  },
  "behavior-kanzxnxi": {
    "type": "ObsoleteBehaviorsList",
    "id": "behavior-kanzxnxi",
    "metaData": {
      "isPreset": false,
      "schemaVersion": "1.0",
      "isHidden": false
    },
    "items": "[{\"name\":\"FloatIn\",\"delay\":0,\"duration\":1.2,\"params\":{\"direction\":\"right\",\"doubleDelayFixed\":true},\"action\":\"screenIn\",\"targetId\":\"\",\"viewMode\":\"DESKTOP\"}]"
  },
  "behavior-kanzxwj6": {
    "type": "ObsoleteBehaviorsList",
    "id": "behavior-kanzxwj6",
    "metaData": {
      "isPreset": false,
      "schemaVersion": "1.0",
      "isHidden": false
    },
    "items": "[{\"name\":\"BackgroundParallax\",\"action\":\"bgScrub\",\"duration\":1,\"delay\":0}]"
  }
}
*/
// get all behaviors
// find all old parallax behaviors
// transform to new

const _ = require('lodash')

const FIXER_BEHAVIORS = {
    BackgroundParallax: [{name: 'BgParallax', action: 'bgScrub', duration: 1, delay: 0}],
    BackgroundReveal: [{name: 'BgReveal', action: 'bgScrub', duration: 1, delay: 0}],
    'BackgroundParallax,BackgroundZoom': [{name: 'BgZoomIn', action: 'bgScrub', duration: 1, delay: 0}],
    BackgroundFadeIn: [{name: 'BgFadeIn', action: 'bgScrub', duration: 1, delay: 0}]
}

function getFixedBehavior(items) {
    const key = _(items).map('name').sort().join()
    return FIXER_BEHAVIORS[key]
}

function getFixedBackgroundBehaviors(items) {
    const bgScrubs = _.filter(items, {action: 'bgScrub'})
    return bgScrubs && getFixedBehavior(bgScrubs)
}

function applyFix(behavior, items, fixed) {
    const fixedItems = _.reject(items, {action: 'bgScrub'}).concat(fixed)
    behavior.items = JSON.stringify(fixedItems)
}

/**
 * Fix bgScrub behaviors to new behaviors per task #BOLT-2314
 * @param {*} pageJson
 */
function fixBackgroundBehaviorsToNewNames(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewer, rendererModel, magicObject) {
    if (!magicObject.isExperimentOpen('bv_scrollEffectsFixer')) {
        return
    }
    const behaviorsData = _.get(pageJson, ['data', 'behaviors_data'])

    _.forEach(behaviorsData, behavior => {
        try {
            const items = behavior.items ? JSON.parse(behavior.items) : []
            const fixed = getFixedBackgroundBehaviors(items)
            if (fixed) {
                applyFix(behavior, items, fixed)
            }
        } catch (e) {
            // If the json is bad, just skip, nothing we can do here
            // There is no logging or bi in data fixer plugins
        }
    })
}

module.exports = {
    exec: fixBackgroundBehaviorsToNewNames,
    FIXER_BEHAVIORS // expose for tests
}
